var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-v2 auth-wrapper auth-bg d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"card-container"},[_c('b-card',{staticClass:"d-flex px-5 pb-5 py-2"},[_c('div',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"","lg":"12"}},[_c('div',{staticClass:"logo-container mb-1"},[_c('b-img',{staticClass:"logo",attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1),_c('b-card-text',{staticClass:"h3 mb-2 font-medium-3 text-center"},[_vm._v(" MINIDC USER ")]),_c('b-card-text',{staticClass:"h2 my-2 text-black fw-bold text-center fw-bold"},[_vm._v(" Sign Up ")]),_c('b-card-text',{staticClass:"mb-5 px-2 font-small-3 text-center"},[_vm._v(" Enter the required credentials below ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"login-name"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-name","state":errors.length > 0 ? false : null,"name":"login-name","placeholder":"Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('ValidationProvider',{attrs:{"name":"Email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"login-phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-phone","state":errors.length > 0 ? false : null,"name":"login-phone","placeholder":"Phone Number"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-confirm-password"}},[_vm._v("Confirm Password")])]),_c('validation-provider',{attrs:{"name":"ConfirmPassword","rules":"required","vid":"confirm_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-confirm-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-confirm-password","placeholder":"············"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-container',{staticClass:"d-flex justify-content-center my-1"},[(_vm.loading)?_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}}):_vm._e()]),_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Submit ")]),_c('b-link',{staticClass:"ml-2",attrs:{"to":{ name: 'auth-login' }}},[_c('small',[_vm._v("Already have an account? Log in here")])])],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }